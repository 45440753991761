import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const TheNavbar = () => {
  const data = useStaticQuery(graphql`
    query InstituteTitlesQuery {
      allStrapiInstitute {
        nodes {
          Sections {
            Title
          }
        }
      }
      allStrapiPage {
        nodes {
          title
          URL
          hidden
        }
      }
    }
  `)

  const [expanded, setExpanded] = React.useState(false)

  return (
    <>
      <Navbar expand="lg">
        <Container className="navbar-container">
          <Link to={"/"} onClick={() => setExpanded(false)}>
            <StaticImage
              src="../images/mmi.png"
              height="140"
              placeholder="BLURRED"
            />
          </Link>
        </Container>
      </Navbar>

      <Navbar expand="lg" sticky="top" id="link-navbar" expanded={expanded}>
        <Container>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="ms-auto"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <NavDropdown
                title="Institut"
                id="basic-nav-dropdown"
                className="px-4"
              >
                {data.allStrapiInstitute.nodes[0].Sections.map(section => (
                  <NavDropdown.Item
                    key={section.Title}
                    href={`/#${section.Title}`}
                    onClick={() => setExpanded(false)}
                  >
                    {section.Title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              {data.allStrapiPage.nodes.map(
                page =>
                  !page.hidden && (
                    <Link
                      key={page.title}
                      className="nav-link px-4"
                      to={`/${page.URL}`}
                      activeClassName="active"
                      onClick={() => setExpanded(false)}
                    >
                      {page.title}
                    </Link>
                  ),
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default TheNavbar
